export const ScDeclaration = [
	{
		id: "selfDec1",
		label: "",
		placeholder: "",
		type: "checkbox",
		validationType: "string",
		value: "",
		options: [
			"I belong to Vagad Visa Oswal Community and Applying for School scholarship for Nursery to Standard 10th *",
		],
		validations: [
			{
				type: "required",
				params: [
					"Self declaration is required to proceed with next steps",
				],
			},
		],
	},
	{
		id: "selfDec2",
		label: "",
		placeholder: "",
		type: "checkbox",
		validationType: "string",
		value: "",
		options: [
			"My Annual Family Income is less than or equal to Rs. 4,00,000 *",
		],
		validations: [
			{
				type: "required",
				params: [
					"Self declaration is needed to proceed with next steps",
				],
			},
		],
	},
	{
		id: "selfDec3",
		label: "",
		placeholder: "",
		type: "checkbox",
		validationType: "string",
		value: "",
		options: [
			"Documents for Upload are ready with me 1) Residence Proof 2) Marksheet Copy 3) Fee Receipt 4) Cancelled Cheque 5) Income Proof 6) Other Supporting documents for Special Aid *",
		],
		validations: [
			{
				type: "required",
				params: [
					"Self declaration is needed to proceed with next steps",
				],
			},
		],
	},
];
