import moment from "moment";
import { ApplicationForm, Template, CLAIMS } from "@anginn/flexi-form";

import { rules } from "@form-data/Laptop/rules";
import { personalData } from "@form-data/Laptop/personalData";
import { educationData } from "@form-data/Laptop/educationData";
import { documentData } from "@form-data/Laptop/documentData";
import familyData from "@form-data/Laptop/familyData";
import { LaptopRuleRegData } from "@form-data/Laptop/LaptopRuleRegData";

const approvals = {
	leaves: [
		{
			leafId: "1one",
			name: "Form Check",
			allowedClaims: [CLAIMS.ADMIN.value],
			mandatory: true,
			atleast: 1,
			leafStatus: true,
			allowReapprovalUponDecline: true,
			allowEditingUponDecline: true,
		},
		{
			leafId: "2two",
			name: "Internal Audit",
			allowedClaims: [CLAIMS.APPROVER.value],
			mandatory: true,
			atleast: 1,
			leafStatus: true,
		},
		{
			leafId: "3three",
			name: "Laptop Delivered",
			allowedClaims: [CLAIMS.ADMIN.value],
			mandatory: true,
			atleast: 1,
			leafStatus: true,
		},
	],
};

const formData = {
	id: "laptop24s8p1",
	name: "Laptop Application Form : 2024 - 2025",
	subtitle: "Laptops Scheme - 8, Phase - 1",
	headerImage: "LaptopBanner3.png",
	templates: {
		0: {
			name: "Self Declaration",
			template: new Template("cvfd", rules, "", false),
			preview: false,
		},
		1: {
			name: "Personal Details",
			template: new Template("gfrt", personalData),
			preview: true,
		},
		2: {
			name: "Education Details",
			template: new Template("vbgb", educationData),
			preview: true,
		},
		3: {
			name: "Family Details",
			template: new Template("mjnh", familyData),
			preview: true,
		},
		4: {
			name: "Document Upload",
			template: new Template("trvc+", documentData),
			preview: true,
		},
	},
	start_time: moment("26/7/2024 00:00", "DD/MM/YYYY HH:mm"),
	end_time: moment("4/11/2024 23:59", "DD/MM/YYYY HH:mm"),
	pretext: LaptopRuleRegData,
	approvals: approvals,
};

const getApplication = () => {
	const temp = Object.assign(new ApplicationForm(), formData);
	temp.parseObject();
	return temp;
};

export const form = getApplication;
