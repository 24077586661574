export const documentData = [
	{
		id: "remark",
		label: "Remark",
		placeholder: "",
		type: "textarea",
		helperText: "Any remark or additional information",
		validationType: "string",
		value: "",
		validations: [],
	},
	{
		id: "textNote",
		label: "",
		placeholder: "",
		type: "statictext",
		validationType: "string",
		value: "* Please note: 1) All documents are mandatory to submit 2) Maximum 2mb file is allowed 3) only JPG, JPEG, PNG, PDF files are allowed",
		validations: [],
		preview: false,
	},
	{
		id: "photo",
		label: "Photo *",
		placeholder: "please upload passport size photograph",
		type: "upload",
		validationType: "string",
		value: "",
		elementWidth: 6,
		validations: [
			{
				type: "required",
				params: ["photo is required"],
			},
			// {
			// 	type: "test",
			// 	params: [
			// 		"filesize",
			// 		"file is too large",
			// 		(values) => {
			// 			console.log(values, values.type, values.size);
			// 			return values && values.size <= FILE_SIZE;
			// 		},
			// 	],
			// },
			// {
			// 	type: "test",
			// 	params: [
			// 		"fileformat",
			// 		"Unsupported Format",
			// 		(value) => value && SUPPORTED_FORMATS.includes(value.type),
			// 	],
			// },
		],
	},
	{
		id: "idCard",
		label: "College/School ID card *",
		placeholder: "School Calendar, ID card whatever is available",
		type: "upload",
		validationType: "string",
		value: "",
		elementWidth: 6,
		validations: [
			{
				type: "required",
				params: ["ID card is required"],
			},
		],
	},
	{
		id: "feeReciept",
		label: "Fee reciept *",
		placeholder: "",
		type: "upload",
		validationType: "string",
		value: "",
		elementWidth: 6,
		validations: [
			{
				type: "required",
				params: ["Fee reciept is required"],
			},
		],
	},
	{
		id: "incomeProof",
		label: "Income Proof *",
		placeholder:
			"ITR, Salary Slip, Letter from Employer on Letterhead of parents / self / gaurdian as the case may be",
		type: "upload",
		validationType: "string",
		value: "",
		elementWidth: 6,
		validations: [
			{
				type: "required",
				params: ["Income proof is required"],
			},
		],
	},
	{
		id: "residenceProof",
		label: "Address Proof *",
		placeholder: "Electricity bill , Rent aggrement, voter id card etc.",
		type: "upload",
		validationType: "string",
		value: "",
		elementWidth: 6,
		validations: [
			{
				type: "required",
				params: ["Address proof is required"],
			},
		],
	},
	{
		id: "markSheetCopyProof",
		label: "Mark Sheet *",
		placeholder: "Latest marksheet",
		type: "upload",
		validationType: "string",
		value: "",
		elementWidth: 6,
		validations: [
			{
				type: "required",
				params: ["Marksheet is required"],
			},
		],
	},
	{
		id: "electricBillProof",
		label: "Electricity Bill *",
		placeholder: "Latest Electricity Bill",
		type: "upload",
		validationType: "string",
		value: "",
		elementWidth: 6,
		validations: [
			{
				type: "required",
				params: ["Electricity Bill is required"],
			},
		],
	},
	{
		id: "aadharCardProof",
		label: "Aadhar Card *",
		placeholder: "Student's Aadhar Card need to be provided",
		type: "upload",
		validationType: "string",
		value: "",
		elementWidth: 6,
		validations: [
			{
				type: "required",
				params: ["Aadhar Card is required"],
			},
		],
	},
	{
		id: "additionalDocs1",
		label: "Additional Document 1",
		placeholder:
			"Any document that does not exist above and is an additional ask by SVGA",
		type: "upload",
		validationType: "string",
		value: "",
		elementWidth: 6,
	},
	{
		id: "additionalDocs2",
		label: "Additional Document 2",
		placeholder:
			"Any document that does not exist above and is an additional ask by SVGA",
		type: "upload",
		validationType: "string",
		value: "",
		elementWidth: 6,
	},
];
