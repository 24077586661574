import React, { Component } from "react";
import { useSelector, useDispatch } from "react-redux";

import Home from "./Home";
import * as applicantActions from "./store/actions/applicant";

export default function Root(props) {
	const dispatch = useDispatch();
	dispatch(
		applicantActions.fetchApplicant(
			localStorage.getItem("id"),
			localStorage.getItem("phoneNo")
		)
	);
	return (
		<div style={{ backgroundColor: "#DCDCDC" }}>
			<Home {...props} />
		</div>
	);
}
