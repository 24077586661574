import { LoginUser, setLoginUser } from "@anginn/flexi-form";
import { firebaseConfig } from "../../config";
import { fetchApplicant } from "./applicant";

export const AUTHENTICATE = "AUTHENTICATE";
export const LOGOUT = "LOGOUT";

let timer;

export const authenticate = (userId, token, refresh_token, expiryTime) => {
	return async (dispatch) => {
		if (expiryTime) {
			dispatch(setLogoutTimer(expiryTime));
		}
		dispatch({
			type: AUTHENTICATE,
			userId: userId,
			token: token,
			refresh_token: refresh_token,
		});
		const userObj = Object.assign(
			new LoginUser(),
			JSON.parse(window.sessionStorage.getItem("loginUser"))
		);
		setLoginUser(userObj);
	};
};

export const login = (aadharNo, contact, token) => {
	console.log("login");
	const apiKey = firebaseConfig.apiKey;
	return async (dispatch) => {
		const response = await fetch(
			`https://identitytoolkit.googleapis.com/v1/accounts:signInWithCustomToken?key=${apiKey}`,
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					token: token,
					returnSecureToken: true,
				}),
			}
		);
		if (!response.ok) {
			const errorResData = await response.json();
		}
		const resData = await response.json();
		dispatch(
			authenticate(
				aadharNo,
				resData.idToken,
				resData.refreshToken,
				parseInt(resData.expiresIn) * 1000
			)
		);
		const expirationDate = new Date(
			new Date().getTime() + parseInt(resData.expiresIn) * 1000
		);
		saveDataToStorage(resData.idToken, expirationDate, aadharNo, contact);
		dispatch(fetchApplicant(aadharNo, contact, resData.idToken));
	};
};

export const logout = () => {
	clearLogoutTimer();
	// localStorage.clear();
	return { type: LOGOUT };
};

const setLogoutTimer = (expirationTime) => {
	return (dispatch) => {
		timer = setTimeout(() => {
			dispatch(logout());
		}, expirationTime);
	};
};

const clearLogoutTimer = () => {
	if (timer) {
		clearTimeout(timer);
	}
};

const saveDataToStorage = async (token, expirationDate, aadharNo, contact) => {
	try {
		localStorage.setItem("idToken", token);
		localStorage.setItem("id", aadharNo);
		localStorage.setItem("phoneNo", contact);
		localStorage.setItem("expiryDate", expirationDate.toISOString());
	} catch (e) {
		console.log(e);
		// saving error
	}
};
