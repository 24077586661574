import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	Avatar,
	Button,
	CssBaseline,
	TextField,
	Link,
	Box,
	Typography,
	Container,
	FormHelperText,
	Grid,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import * as applicantActions from "../store/actions/applicant";
import * as authActions from "../store/actions/auth";
import { SERVER_URL } from "../config";
import banner from "../static/img/banner.png";

function MainWebsite() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"Visit to stay updated "}
			<Link color="inherit" href={"https://www.svgaindia.org/en/"}>
				svgaindia.org
			</Link>
		</Typography>
	);
}

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"Copyright © "}
			<Link color="inherit" href={SERVER_URL}>
				SVGA
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

export default function SignIn(props) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// Inputs
	const [aadharNo, setAadharNo] = useState("");
	const [phoneNo, setPhoneNo] = useState("");
	const [isContactRequired, setIsContactRequired] = useState(false);

	const [otp, setotp] = useState("");
	const [show, setshow] = useState(false);
	const [disableAadharInput, setDisableAadharInput] = useState(false);
	const [final, setfinal] = useState("");
	const [isError, setError] = React.useState(false);
	const [errMsg, setErrMsg] = React.useState("");
	const [oError, setOError] = React.useState(false);
	const [oErrMsg, setOErrMsg] = React.useState("");
	const [helperText, setHelperText] = React.useState("");
	const [loading, setLoading] = useState(false); // Added loading state

	const token = useSelector((state) => state.auth.token);

	const loadNumber = () => {
		let temp = {
			aadharNo,
		};
		if (isContactRequired) {
			temp = {
				aadharNo: aadharNo,
				contact: phoneNo,
			};
		}
		setLoading(true); // Set loading to true before the API call
		fetch(`${SERVER_URL}/api/sendOTP`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(temp),
		})
			.then((res) => res.json())
			.then((json) => {
				if (!json.error) {
					setPhoneNo(json.contact);
					setHelperText(json.msg);
					setDisableAadharInput(true);
					setshow(true);
				} else {
					setIsContactRequired(true);
					setDisableAadharInput(true);
					setHelperText(json.msg);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const ValidateContact = () => {
		setError(false);
		setErrMsg("");
		let tempError = false;
		if (!aadharNo) {
			setError(true);
			setErrMsg("Please enter contact number to login");
			tempError = true;
		} else if (!aadharNo.match(/^[0-9]+$/)) {
			setError(true);
			setErrMsg("Contact number must be in digits");
			tempError = true;
		} else if (aadharNo.length < 12) {
			setError(true);
			setErrMsg("Contact number can not be less than 12 digits");
			tempError = true;
		} else if (aadharNo.length > 12) {
			setError(true);
			setErrMsg("Contact number can not be more than 12 digits");
			tempError = true;
		} else {
			setError(false);
			setErrMsg("");
			tempError = false;
		}
		return tempError;
	};
	// Sent OTP
	const signin = () => {
		const tempError = ValidateContact();
		if (tempError) return;
		loadNumber();
	};

	const handleChange = (e) => {
		setAadharNo(e.target.value);
		// ValidateContact();
	};

	const handlePhoneNoChange = (e) => {
		setPhoneNo(e.target.value);
		// ValidateContact();
	};

	// Validate OTP
	const ValidateOtp = () => {
		if (otp === null || final === null) return;
		fetch(`${SERVER_URL}/api/verifyOTP`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				aadharNo: aadharNo,
				contact: phoneNo,
				otp: otp,
			}),
		})
			.then((res) => res.json())
			.then((json) => {
				if (!json.error) {
					dispatch(
						authActions.login(aadharNo, phoneNo, json.id_token)
					);
					dispatch(
						applicantActions.fetchApplicant(aadharNo, phoneNo)
					);
					// props.history.replace("/home");
				} else {
					setOError(true);
					setOErrMsg(json.msg);
				}
			})
			.catch((err) => {
				console.log(err);
				// alert("Wrong code");
				setOError(true);
				setOErrMsg("Incorrect OTP code");
			});
	};

	if (token != null) {
		navigate("/home", { replace: true });
	}

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				margin: 2,
			}}
		>
			<img
				src={banner}
				alt="SVGA-banner"
				style={{ width: "100%", maxWidth: "1000px", margin: "0 auto" }}
			/>
			<Grid container spacing={5}>
				<Grid item xs={12} sm={12} md={4}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<CssBaseline />

						<Avatar
							sx={{ m: 1, backgroundColor: "secondary.main" }}
						>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h6">
							{
								"Sign in to apply for SVGA's events, schemes or membership registration"
							}
						</Typography>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="contact"
							label="Applicant / Student's Aadhar Card Number"
							name="aadhar"
							placeholder="Enter 12 digits aadhar number"
							autoFocus
							onChange={handleChange}
							helperText={errMsg}
							error={isError}
							value={aadharNo}
							disabled={disableAadharInput}
						/>
						<FormHelperText>{helperText}</FormHelperText>
						{isContactRequired && (
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="contact"
								label="Contact Number"
								name="contact"
								placeholder="Enter 10 digits contact number"
								autoFocus
								onChange={handlePhoneNoChange}
								helperText={errMsg}
								error={isError}
								value={phoneNo}
								disabled={show}
							/>
						)}

						{show && (
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="otp"
								label="OTP"
								id="otp"
								error={oError}
								helperText={oErrMsg}
								onChange={(e) => {
									setotp(e.target.value);
								}}
							/>
						)}

						{!show && <div id="recaptcha-container"></div>}
						{!show && (
							<Button
								// id="sign-in-button"
								fullWidth
								variant="contained"
								color="primary"
								sx={{ mt: 1, mb: 2 }}
								onClick={signin}
								disabled={loading}
							>
								{loading ? "Sending OTP..." : "Send OTP"}
							</Button>
						)}
						{show && (
							<Button
								// id="sign-in-button"
								fullWidth
								variant="contained"
								color="primary"
								sx={{ mt: 1, mb: 2 }}
								onClick={ValidateOtp}
							>
								Verify
							</Button>
						)}
						<Button
							// id="sign-in-button"
							fullWidth
							variant="contained"
							color="primary"
							sx={{ mt: 1, mb: 2 }}
							onClick={() => {
								setAadharNo("");
								setotp("");
								setshow(false);
								setError(false);
								setErrMsg("");
								setfinal("");
								setIsContactRequired(false);
								setDisableAadharInput(false);
								setHelperText("");
							}}
						>
							Reset
						</Button>
						<Link href="/changeContact" variant="body2">
							{"Contact changed?"}
						</Link>
						<Box mt={2}>
							<MainWebsite />
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<iframe
						src="https://www.instagram.com/svgaindia/embed"
						width="100%"
						height="500px"
						style={{ border: "none", overflow: "hidden" }}
						scrolling="no"
						allowTransparency="true"
						allow="encrypted-media"
					></iframe>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					{/* <Box
						sx={{
							// display: "flex",
							// flexDirection: "column",
							alignItems: "center",
							maxWidth: "95%",
						}}
					> */}
					<Typography variant="h1" gutterBottom align="justify">
						About SVGA
					</Typography>
					<Typography variant="h6" gutterBottom align="justify">
						SHREE VAGAD GRADUATE’S ASSOCIATION has been formed to
						bring together the graduates and facilitate better
						Coordination and Cooperation among them, thereby
						extending and expanding Shree Kutchi Vagad Visa Oswal
						Samaj’s horizons. Our association will strive to develop
						a stronger and more positive approach toward higher
						education in our community. Our desire is to spread such
						awareness in our Vagad community that each and every
						person should feel and demand that “Higher education is
						my Birthright and I will get it”. This is an appeal to
						come together and step into the 21st century with
						confidence. As you are aware with the approaching
						millennium, the world and humanity are undergoing a ‘sea
						change’- not a single aspect being left untouched as we
						attempt to become part of ‘The Global Village We not
						only have to keep pace with the fast-changing times, but
						also be radical if we are to survive and, then outpace
						others. If we do not, we will be caught in a time warp’
						and emceed therein, left behind to wait, watch and
						regret forever, whilst the world surges ahead. True, our
						VAGAD community is undergoing a transformation, but too,
						too slow, we have a very long way to go to catch up and
						time is running out on us too fast. Colleagues; does our
						‘community’ not have the potential? the caliber? the
						strength of purpose? We certainly do! We have only to
						channel our efforts and energy, by coming together.
						‘Strength lies in Unity’. This is an appeal to all
						graduates and postgraduates to join SVGA, as life
						members, an Association formed in 1994, to enable our
						community to go ahead and launch into the mainstream.
					</Typography>
					<Typography variant="h1" gutterBottom align="justify">
						What SVGA does do?
					</Typography>
					<Typography variant="body1">
						<ul>
							<li>
								This Association of graduates and postgraduates
								aims to encourage and promote higher education
								in our community.
							</li>
							<li>
								It also seeks to provide a common platform for
								promoting meaningful interaction among the
								intellectuals so as to mold them as an agent of
								positive change.
							</li>
							<li>
								Through its year-round itinerary of seminars,
								workshops, discussions, contests, and
								educational excursions, it provides ample
								opportunities to its members to enrich their
								personalities & broaden their outlooks.
							</li>
							<li>
								Seminars on taxation, the Internet, capital
								market review, first aid, etc., keep members
								updated on the latest information they need to
								know, thus promoting self-development.
							</li>
							<li>
								It seeks to enhance the working capabilities of
								the members with the aid of workshops such as
								“Alfa Mind training” on improving brainpower &
								memory.
							</li>
							<li>
								Competitions such as the ‘Healthy Baby Contest”
								promote awareness of vital issues such as
								childcare in a spirit of fun and education.
							</li>
							<li>
								Quiz and Personality Context (Abhivyakti) brings
								out the hidden talent in our community.
							</li>
							<li>
								Seminars & workshops like Aptitude Test and
								Career Counseling help students chart their
								future plans.
							</li>
						</ul>
					</Typography>
					<Typography variant="h6" gutterBottom align="justify">
						SVGA seeks to promote the concept that learning can be
						fun. Education does not have to end with graduation or
						post-graduation. It helps the members to respond to
						environmental impediments and opportunities effectively,
						efficiently, and expeditiously as an overwhelming
						requirement for continuing excellence. With the
						cooperation and support of each & every graduate and
						postgraduate of our community, we will strengthen our
						resolve and efforts to remain in today’s mainstream. We
						thus request you to join SVGA and through its aim and
						objectives, enable our community to go ahead, set an
						example, and pave the way for others. Do spread the word
						to your fellow colleagues.
					</Typography>
					<Typography variant="h1" gutterBottom align="justify">
						Aim & Objective
					</Typography>
					<Typography variant="body1">
						<ul>
							<li>
								To conduct programs and get-togethers of the
								Graduates of our Community.
							</li>
							<li>
								To promote Higher education and to encourage
								today’s youngsters to develop a positive and
								constructive approach and instill leadership
								skills in them.
							</li>
							<li>
								To set up a full-fledged Information and
								Guidance Bureau.
							</li>
							<li>
								To increase the number of Graduates and aim to
								have a minimum of one graduate per family.
							</li>
							<li>
								To build a pool of Graduates, who can provide
								proper guidance to our community, and to young
								students, who can benefit from their knowledge
								and experience.
							</li>
							<li>
								To facilitate overall individual and
								professional development by conducting seminars
								on legal, taxation, and general subjects.
							</li>
							<li>
								To establish a successful and constructive blend
								of the educational activities of various
								associations in our community.
							</li>
							<li>
								To spread general and medical awareness among
								the whole community.
							</li>
						</ul>
					</Typography>
				</Grid>
			</Grid>
			<Copyright />
		</Box>
	);
}
