import moment from "moment";
import { ApplicationForm, Template } from "@anginn/flexi-form";

import { rules } from "@form-data/Laptop/rules";
import { personalData } from "@form-data/Laptop/personalData";
import { educationData } from "@form-data/Laptop/educationData";
import { documentData } from "@form-data/Laptop/documentData";
import familyData from "@form-data/Laptop/familyData";
import { LaptopRuleRegData } from "@form-data/Laptop/LaptopRuleRegData";

export const form = new ApplicationForm(
	"senfol",
	"Laptop Application Form : 2022 - 2023",
	"Laptops Scheme - 6, Phase - 3",
	{
		0: {
			name: "Self Declaration",
			template: new Template("sdfwe", rules, "", false),
			preview: false,
		},
		1: {
			name: "Personal Details",
			template: new Template("gsdr", personalData),
			preview: true,
		},
		2: {
			name: "Education Details",
			template: new Template("sderge", educationData),
			preview: true,
		},
		3: {
			name: "Family Details",
			template: new Template("dflnr", familyData),
			preview: true,
		},
		4: {
			name: "Document Upload",
			template: new Template("dkfnbf+", documentData),
			preview: true,
		},
	},
	moment("9/10/2022 00:00", "DD/MM/YYYY HH:mm"),
	moment("15/10/2022 23:59", "DD/MM/YYYY HH:mm"),
	LaptopRuleRegData
);
