import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Typography, Button, Link as MuiLink } from "@mui/material";
import { useLocation } from "react-router-dom";

import FlexiForm from "./Container/FlexiForm";
import Header from "./Pages/header";
import * as applicantActions from "./store/actions/applicant";

function Home(props) {
	const applicant = useSelector((state) => state.applicant.applicant);
	const forms = useSelector((state) => state.forms);

	const dispatch = useDispatch();
	const location = useLocation();

	if (applicant === undefined) {
		return "Loading Applicant details";
	}

	const application = applicant.getApplication(
		location.pathname.replace("/", ""),
		forms.applications
	);

	if (application === undefined) {
		return "Unable to load application";
	}
	const formId = application.id;
	const steps = application.getStepList();

	const handleSubmission = (data, activeStep, isFinalSubmit) => {
		const templates = application.getTemplateList();
		const template = templates[activeStep];
		dispatch(
			applicantActions.updateTemplate(
				activeStep,
				template,
				location.state.formId,
				isFinalSubmit
			)
		);
	};

	function getStepContent() {
		if (application.getTemplateList().length === 0) {
			return;
		}
		return (
			<div>
				<FlexiForm
					formId={formId}
					jsonData={application}
					handleSubmission={handleSubmission}
				/>
			</div>
		);
	}

	if (application.getTemplateList() === undefined) {
		return;
	}
	if (application.getTemplateList().length === 0) {
		return <Typography sx={{ mt: 1, mb: 1, ml: 2 }}>Loading</Typography>;
	}
	return (
		<Box
			sx={{
				flexGrow: 1,
				width: "60%",
				"@media (max-width: 1280px)": {
					width: "100%",
				},
				marginLeft: "auto",
				marginRight: "auto",
				py: 1,
			}}
		>
			<Header hideImage={false} username={applicant.aadhaar_no} />
			<Grid>
				<div>
					{application.getCurrentStep() === steps.length ? (
						<Box
							display="flex"
							sx={{
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							<Typography sx={{ mt: 1, mb: 1, ml: 2 }}>
								All steps completed - your form has been
								successfully submitted !!
							</Typography>
							<br />
							<Button
								variant="contained"
								color="primary"
								component={MuiLink}
								sx={{
									alignSelf: "center",
									maxWidth: 220,
									ml: 1,
								}}
								to={{
									pathname: "/home",
								}}
							>
								Back to home
							</Button>
						</Box>
					) : (
						getStepContent()
					)}
				</div>
			</Grid>
		</Box>
	);
}

export default Home;
