import React from "react";
import { Container, Typography, Paper, Divider } from "@mui/material";
import banner from "../static/img/banner.png";

const PolicyPage = () => {
	const policies = [
		{
			id: "privacy",
			title: "Privacy Policy",
			details: `
      We are committed to protecting the privacy and confidentiality of our users. This policy outlines how we collect, use, and safeguard your personal information when you interact with our website or make donations, pay membership fees, or participate in events hosted by our SVGA.

      **1. Information Collection:**
      We collect personal details such as your name, email, phone number, and payment details when you fill out forms on our website.
      This information is used solely for processing your transactions, communicating with you about our initiatives, and complying with legal requirements.

      **2. Use of Information:**
      The personal information collected is used for processing payments, managing event participation, handling membership details, and keeping you informed about our activities and campaigns. We do not sell, share, or distribute your personal information to third parties.

      **3. Data Security:**
      We employ industry-standard measures to ensure that your data is protected. All payment transactions are securely processed through accredited third-party payment gateways. We take every precaution to protect your data against unauthorized access, modification, or misuse.

      **4. Cookies by Third Parties:**
	  While we do not use cookies directly on our website, third-party services such as payment gateways or analytics providers may use cookies to enhance their services. These cookies help facilitate secure transactions or analyze website traffic, and are governed by the privacy policies of those third parties. You may manage or disable cookies through your browser settings, though this may affect your interaction with these external services.

      **5. Retention of Information:**
      Your personal data is retained for as long as necessary to fulfill the purposes outlined in this policy or as required by law. You may request the deletion of your data at any time by contacting us at svgaindia@gmail.com.

      **6. Changes to Policy:**
      We reserve the right to modify this privacy policy at any time. Any changes will be updated on this page and communicated to users as required.

      If you have any questions regarding our privacy practices, please reach out to us at svgaindia@gmail.com.
      `,
		},
		{
			id: "terms",
			title: "Terms and Conditions",
			details: `
      These Terms and Conditions govern your use of our website, including donations, membership sign-ups, event participation, and any other services provided by our SVGA. By accessing or using our website, you agree to be bound by these terms.

      **1. Payments:**
      All payments for donations, membership fees, and event fees are processed securely. The amount to be paid may vary based on the dynamic options selected within the forms, and users will be clearly informed of the total amount before proceeding to the payment gateway. We do not accept responsibility for incorrect payment details provided by the user.

      **2. Event Participation:**
      By registering for events, you agree to abide by the rules and guidelines set forth for the event. Fees for participation will be displayed on the relevant form, and once submitted, event participation fees are generally non-refundable, except as noted in our cancellation and refund policy.

      **3. Membership:**
      Membership fees are non-transferable and non-refundable unless stated otherwise. Memberships may have different tiers and associated fees,
      which will be detailed on the respective forms.

      **4. Website Usage:**
      Users are prohibited from engaging in any activities that could harm the website or its services, including but not limited to hacking, introducing viruses, or using the website for fraudulent purposes.

      **5. Amendments:**
      We reserve the right to update or amend these terms at any time. Continued use of our website following any changes implies acceptance of the new terms.
      Please review these terms periodically for any updates.
      `,
		},
		{
			id: "cancellation",
			title: "Cancellation and Refund",
			details: `
      Our cancellation and refund policy covers all donations, membership fees, and event fees collected through the website.

      **1. Donations:**
      All donations made to our SVGA are voluntary and non-refundable. However, in cases of errors (such as duplicate transactions), please contact us within 7 business days, and we will review the issue. Refunds for donations are subject to the approval of our committee.

      **2. Membership Fees:**
      Membership fees are generally non-refundable once paid. In exceptional cases, such as cancellation of membership due to SVGA policy changes, a pro-rated refund may be issued based on the circumstances and at the discretion of our management.

      **3. Event Fees:**
      Fees paid for participating in events are refundable only if the event is canceled by the SVGA or if a cancellation request is submitted at least 5 business days before the event. Refunds for event fees may take up to 14 business days to process.

      **4. Refund Process:**
      Approved refunds will be processed through the same payment method used for the transaction. It may take several days for the refund to reflect in your account, depending on your bank or payment service provider.

      **5. Contact for Refunds:**
      To request a refund, contact us at svgaindia@gmail.com, and provide details of your payment and the reason for your request. Our team will review and respond to your request within 10 business days.
      `,
		},
		{
			id: "shipping",
			title: "Shipping and Delivery",
			details: `
      While our SVGA primarily handles donations, memberships, and events, in certain cases, we may ship physical items related to specific donations or event participation.

      **1. Delivery of Items:**
      If your contribution includes a reward or item for your donation (e.g., t-shirts, memorabilia), we will ship these items to the address provided during the donation process. Delivery timelines will be communicated at the time of the donation or event.

      **2. Shipping Costs:**
      Shipping costs, if any, will be indicated at the time of the donation or event registration. Depending on your location, international shipping charges may apply.

      **3. Delays and Issues:**
      We strive to deliver all items within the promised timeframe, but unforeseen delays may occur. If there are any delays or issues with the shipment, we will notify you via email. Please ensure the address provided is accurate to avoid any delivery issues.

      **4. Non-receipt of Items:**
      If you do not receive your item within the specified delivery time, please contact us at svgaindia@gmail.com. We will investigate the issue and work with our shipping partners to resolve it.
      `,
		},
		{
			id: "contact",
			title: "Contact Us",
			details: `
      We are here to help you with any questions, concerns, or feedback you may have regarding our website, donations, memberships, events, or any of
      our policies.

      **Contact Information:**
      - Email: svgaindia@gmail.com
      - Landline: 022-2432 7842
	  - Phone +91 90223 13382

      - Office Address:
	  A/001, Gulmohar CHSL., Ground Floor,
	  P. L. Kale Guruji Marg,
	  Off Ranade Road, Next to Bhandari Co-Op. Bank,
	  Dadar West,
	  Mumbai - 400028
	  Maharashtra, India

      **Office Hours:**
      Our support team is available Tuesday to Sunday, 11 AM to 5 PM (IST). Please allow up to 2 business days for a response to your inquiries.

      We value your feedback and appreciate your support of our SVGA's mission!
      `,
		},
	];

	return (
		<Container maxWidth="md" sx={{ py: 4 }}>
			<img
				src={banner}
				alt="SVGA-banner"
				style={{ width: "80%", margin: "0 auto" }}
			/>
			<Typography variant="h4" gutterBottom align="center">
				Shree Vagad Graduates Association (SVGA) Term's and Policy
			</Typography>

			{policies.map((policy) => (
				<Paper key={policy.id} elevation={3} sx={{ mb: 4, p: 3 }}>
					<Typography variant="h5" gutterBottom>
						{policy.title}
					</Typography>
					<Divider sx={{ mb: 2 }} />
					<Typography
						variant="body1"
						component="div"
						sx={{ whiteSpace: "pre-line" }}
					>
						{policy.details}
					</Typography>
				</Paper>
			))}
		</Container>
	);
};

export default PolicyPage;
