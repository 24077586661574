import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import formsReducer from "./store/reducers/forms";
import applicantReducer from "./store/reducers/applicant";
import authReducer from "./store/reducers/auth";
import { FormContextProvider } from "@anginn/flexi-form";
import getEnvironment from "./env";

const rootReducer = combineReducers({
	forms: formsReducer,
	applicant: applicantReducer,
	auth: authReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

const root = document.getElementById("root");
const reactRoot = createRoot(root);

reactRoot.render(
	<>
		<FormContextProvider config={getEnvironment()}>
			<BrowserRouter>
				<CssBaseline />
				<Provider store={store}>
					<App />
				</Provider>
			</BrowserRouter>
		</FormContextProvider>
	</>
);
