// const phoneRegExp =
// 	"^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$";

export const ScBankData = [
	{
		id: "Account holder name",
		label: "Account Holder's Name *",
		placeholder: "",
		type: "text",
		helperText:
			"Scholorship cheque required in which name, the name should exactly match with passbook or cancelled cheque",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Account Holder's Name is required"],
			},
		],
	},
	{
		id: "Relationship",
		label: "Relationship with Applicant",
		placeholder: "",
		type: "radio",
		validationType: "string",
		value: "",
		options: ["Father", "Mother", "Other"],
		validations: [],
	},
	{
		id: "Bank Name",
		label: "Bank Name *",
		placeholder: "",
		type: "text",
		helperText: "",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Bank Name is required"],
			},
		],
	},
	{
		id: "Bank Branch",
		label: "Bank Branch *",
		placeholder: "",
		type: "text",
		helperText: "",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Branch Name is required"],
			},
		],
	},
	{
		id: "Bank Account Number",
		label: "Bank Account Number *",
		placeholder: "",
		type: "text",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Bank account number is required"],
			},
			{
				type: "matches",
				params: ["^[0-9]+$", "Bank account number must only be digits"],
			},
			{
				type: "max",
				params: [
					18,
					"Bank account number can not be more than 18 digits",
				],
			},
			{
				type: "min",
				params: [
					9,
					"Bank account number can not be less than 9 digits",
				],
			},
		],
	},
	{
		id: "IFSC code",
		label: "Bank IFSC details *",
		placeholder: "",
		type: "text",
		helperText: "",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["IFSC code of your bank is required"],
			},
			{
				type: "max",
				params: [11, "IFSC code can not be more than 11 digits"],
			},
			{
				type: "min",
				params: [11, "IFSC code can not be less than 11 digits"],
			},
		],
	},
	{
		id: "recieved scholorship earlier",
		label: "Have you or your sibling received the scholorhip from SVGA ealier ? ",
		placeholder: "",
		type: "radio",
		validationType: "string",
		value: "",
		options: ["Yes", "No"],
		validations: [],
	},
	{
		id: "selfDec bank details",
		label: "",
		placeholder: "",
		type: "checkbox",
		validationType: "string",
		value: "",
		options: [
			"Above information given by me is true to my knowledge and this application will be deemed to be void if the information provided is found wrong. SVGA reserves right to reject any application without giving any specific reason to applicant *",
		],
		validations: [
			{
				type: "required",
				params: [
					"Self declaration is required to proceed with next steps",
				],
			},
		],
	},
];
