import React from "react";
import { useNavigate } from "react-router-dom";
import {
	Card,
	CardContent,
	Typography,
	CardActionArea,
	CardActions,
	Button,
	CardMedia,
} from "@mui/material";
import DraftsTwoToneIcon from "@mui/icons-material/DraftsTwoTone";
import EventAvailableTwoToneIcon from "@mui/icons-material/EventAvailableTwoTone";
import { FormResponseState } from "@anginn/flexi-form";

const FormCard = ({ form, status, isApplied }) => {
	const navigate = useNavigate();

	const getCardTitle = () => {
		if (status === FormResponseState.UPCOMING)
			return "Upcoming " + form.name;
		if (status === FormResponseState.EXPIRED) return "Expired " + form.name;
		return form.name;
	};

	const getIcon = () => {
		if (status === FormResponseState.OPEN)
			return <EventAvailableTwoToneIcon sx={{ color: "white" }} />;
		return <DraftsTwoToneIcon sx={{ color: "white" }} />;
	};

	const getHelperTextColor = () => {
		switch (status) {
			case FormResponseState.ACTIVE:
				return form.submitted ? "success" : "warning";
			case FormResponseState.DECLINE:
				return "error";
			case FormResponseState.EXPIRED:
				return "error";
			case FormResponseState.UPCOMING:
				return "info";
			case FormResponseState.OPEN:
				return "textSecondary";
			default:
				return "textSecondary";
		}
	};

	const handleCardClick = () => {
		navigate(form.id, {
			state: {
				path: form.id,
				formId: form.id,
			},
		});
	};

	const ActionButton = ({ label, to, variant = "text" }) => (
		<Button
			size="small"
			color="primary"
			variant={variant}
			onClick={() => {
				navigate(to.pathname, {
					state: to.state,
				});
			}}
		>
			{label}
		</Button>
	);

	const renderCardActions = () => {
		const commonTo = {
			pathname: `/${form.id}`,
			state: {
				path: form.id,
				formId: form.id,
			},
		};

		const actionsConfig = {
			[FormResponseState.ACTIVE]: [
				{ label: "Edit", to: commonTo, variant: "outlined" },
				{
					label: "Preview",
					to: { ...commonTo, pathname: "/FormPreview" },
				},
			],
			[FormResponseState.DECLINE]: [
				{ label: "Resubmit", to: commonTo, variant: "outlined" },
				{
					label: "Preview",
					to: { ...commonTo, pathname: "/FormPreview" },
				},
			],
			[FormResponseState.EXPIRED]: [
				{
					label: "Preview",
					to: { ...commonTo, pathname: "/FormPreview" },
				},
			],
			[FormResponseState.UPCOMING]: [],
			[FormResponseState.OPEN]: [
				{ label: "Apply", to: commonTo, variant: "outlined" },
			],
		};

		return actionsConfig[status]?.map((action) => (
			<ActionButton key={action.label} {...action} />
		));
	};

	return (
		<Card sx={{ maxWidth: 360, m: 2 }}>
			<CardActionArea>
				<CardMedia
					component="div"
					title="Form Icon"
					sx={{
						height: 30,
						bgcolor: "#3f51b5",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{getIcon()}
				</CardMedia>
				<CardContent>
					<Typography gutterBottom variant="h5">
						{getCardTitle()}
					</Typography>
					<Typography
						variant="body2"
						color="textSecondary"
						component="p"
					>
						{form.subtitle}
					</Typography>
					<Typography variant="caption" color={getHelperTextColor()}>
						{form.getFormHelperText(isApplied)}
					</Typography>
				</CardContent>
			</CardActionArea>
			<CardActions>{renderCardActions()}</CardActions>
		</Card>
	);
};

export default FormCard;
