export const educationData = [
	{
		id: "10thBoard",
		label: "10th Board *",
		placeholder: "",
		type: "condSelect",
		helerText: "SSC/CBSE/IGSE/IB",
		validationType: "string",
		value: "",
		options: [
			"SSC",
			"CBSE",
			"ICSE",
			"IB",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Board is required"],
			},
		],
		action: ["required"],
		isProfile: true,
		isEditableProfile: false,
	},
	{
		id: "10thPercentage",
		label: "Percentage or Grade scored in 10th *",
		placeholder: "",
		type: "text",
		helperText: "Percentage obtained in 10th",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["10th Percentage is required"],
			},
		],
		action: ["required"],
		isProfile: true,
		isEditableProfile: false,
	},
	{
		id: "studyCompleted2023-2024",
		label: "Last year's education completed in 2023-24 *",
		placeholder: "",
		type: "condSelect",
		helperText: "Course/Standard completed",
		validationType: "string",
		value: "",
		options: [
			"12th",
			"Diploma",
			"1st year",
			"1st year",
			"2nd year",
			"3rd year",
			"4th year",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Education completed is required"],
			},
		],
		isProfile: true,
		isEditableProfile: false,
	},
	{
		id: "percentage2022-2023",
		label: "Percentage or Grade in 2023-24 *",
		placeholder: "",
		type: "text",
		helperText: "Please fill % or Grade obtained in previous year",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Previous year % or Grade is required"],
			},
		],
		isProfile: true,
		isEditableProfile: false,
	},
	{
		id: "12thBoard",
		label: "12th Board *",
		placeholder: "",
		type: "condSelect",
		helerText: "SSC/CBSE/IGSE/IB",
		validationType: "string",
		value: "",
		options: [
			"HSC",
			"CBSE",
			"ICSE",
			"IB",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Board is required"],
			},
		],
		action: ["required"],
		isProfile: true,
		isEditableProfile: false,
		rules: {
			or: [{ "!=": [{ var: "studyCompleted2023-2024" }, "Diploma"] }],
		},
	},
	{
		id: "12thPercentage",
		label: "Percentage or Grade scored in 12th *",
		placeholder: "",
		type: "text",
		helperText: "Percentage obtained in 12th",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["12th Percentage is required"],
			},
		],
		action: ["required"],
		isProfile: true,
		isEditableProfile: false,
		rules: {
			or: [{ "!=": [{ var: "studyCompleted2023-2024" }, "Diploma"] }],
		},
	},
	{
		id: "course2024-2025",
		label: "Course *",
		placeholder: "",
		helperText:
			"Select current Course, should be a student from the following options",
		type: "condSelect",
		validationType: "string",
		value: "",
		options: [
			"Engineering",
			"Architect",
			"Interior Designing",
			"Video Editing / VFX",
			"Graphic Desigining",
			"Commerce",
			"Arts",
			"Medical",
			"Science(BSc / MSc)",
			"Masters",
			"PhD",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["course is required"],
			},
		],
		action: ["required"],
		isProfile: true,
		isEditableProfile: false,
	},
	{
		id: "college2024-2025",
		label: "Current college / institution Name *",
		placeholder: "",
		type: "text",
		helperText: "College / Institution",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["College / Institution name is required"],
			},
		],
		isProfile: true,
		isEditableProfile: false,
	},
	{
		id: "CurrentUniversity2024-2025",
		label: "Current University / Institute *",
		placeholder: "",
		type: "condSelect",
		helerText: "Mumbai Univeristy / SNDT / Others ?",
		validationType: "string",
		value: "",
		options: [
			"Mumbai University",
			"SNDT",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["University / Institute is required"],
			},
		],
		action: ["required"],
		isProfile: true,
		isEditableProfile: false,
	},
	{
		id: "studyCompleted2024-2025",
		label: "Study Applied in 2024-25 *",
		placeholder: "Study applied in 2024-25",
		type: "condSelect",
		helperText: "",
		validationType: "string",
		value: "",
		options: [
			"1st year",
			"2nd year",
			"3rd year",
			"4th year",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Please specify your course"],
			},
		],
		isProfile: true,
		isEditableProfile: false,
	},
	{
		id: "pastScholarshipReceived",
		label: "SVGA Scholarship Received? *",
		placeholder: "",
		type: "radio",
		helerText: "Mention YES in case of any scholarship received from SVGA",
		validationType: "string",
		value: "",
		options: ["YES", "NO"],
		validations: [
			{
				type: "required",
				params: ["Select any one"],
			},
		],
	},
	{
		id: "pastLaptopApplied",
		label: "Received laptop in past from SVGA? *",
		placeholder: "",
		type: "radio",
		helerText:
			"Mention YES in case of laptop received from SVGA in past few years",
		validationType: "string",
		value: "",
		options: ["YES", "NO"],
		validations: [
			{
				type: "required",
				params: ["Select any one"],
			},
		],
	},
	{
		id: "computerUsage",
		label: "Computer usage in your Studies *",
		placeholder: "",
		type: "textarea",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["This field is required"],
			},
		],
	},
	{
		id: "computerKnowledge",
		label: "Computer Knowledge *",
		placeholder: "Computer Courses done",
		type: "text",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Computer Knowledge is required"],
			},
		],
	},
	{
		id: "laptopPreference",
		label: "laptop Preference *",
		placeholder: "",
		type: "select",
		helperText:
			"HP 15s-Intel 12th Gen i3-1215U	8GB  RAM	512GB SSD	Intel UHD Graphics Windows 11 Microsoft office Home & Student Edition 2021	Island KBD with N Pad15.6 FHD  Narrow Border /NSV	@36000. ચૂકવવાની રકમ ₹18000",
		validationType: "string",
		value: "",
		options: ["Intel i3 laptop"],
		validations: [
			{
				type: "required",
				params: ["Config is required"],
			},
		],
		action: ["required"],
		rules: {
			or: [
				{ "==": [{ var: "course2024-2025" }, "Commerce"] },
				{ "==": [{ var: "course2024-2025" }, "Arts"] },
				{ "==": [{ var: "course2024-2025" }, "Medical"] },
				{ "==": [{ var: "course2024-2025" }, "Science(BSc / MSc)"] },
				{ "==": [{ var: "course2024-2025" }, "Masters"] },
				{ "==": [{ var: "course2024-2025" }, "PhD"] },
			],
		},
	},
	{
		id: "laptopPreferenceHighEnd",
		label: "laptop Preference *",
		placeholder: "",
		type: "select",
		helperText:
			"HP Laptop 15s, fy5009TU 12th Gen Intel Core i5-1235U, 15.6-inch (39.6 cm), FHD, 16GB DDR4, 512GB SSD, Intel Iris Xe graphics, 720p HD camera, Backlit KB, Thin & Light (Win 11, Silver, 1.69 kg), @50000. ચૂકવવાની રકમ ₹25000",
		validationType: "string",
		value: "",
		options: ["Intel i5 laptop"],
		validations: [
			{
				type: "required",
				params: ["Config is required"],
			},
		],
		action: ["required"],
		rules: {
			or: [
				{ "==": [{ var: "course2024-2025" }, "Engineering"] },
				{ "==": [{ var: "course2024-2025" }, "Architect"] },
				{ "==": [{ var: "course2024-2025" }, "Interior Designing"] },
				{ "==": [{ var: "course2024-2025" }, "Video Editing / VFX"] },
				{ "==": [{ var: "course2024-2025" }, "Graphic Desigining"] },
			],
		},
	},
];
