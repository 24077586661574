// import { ACCESS_TOKEN } from "../../config";
import { AUTHENTICATE, LOGOUT } from "../actions/auth";

const initialState = {
	token: null,
	refresh_token: null,
	userId: null,
	contact: null,
	autoLogin: 0,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case AUTHENTICATE:
			return {
				token: action.token,
				refresh_token: action.refresh_token,
				userId: action.userId,
				contact: action.contact,
				autoLogin: initialState.autoLogin + 1,
			};
		case LOGOUT:
			console.log("LOGOUT in auth");
			localStorage.removeItem("id");
			localStorage.removeItem("idToken");
			localStorage.removeItem("phoneNo");
			localStorage.removeItem("expiryDate");
			return initialState;
		default:
			return state;
	}
};
