const phoneRegExp =
	"^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$";

export const pg1 = [
	{
		id: "name",
		label: "First Name *",
		placeholder: "First Name",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		elementWidth: 6,
		validations: [
			{
				type: "required",
				params: ["First name is required"],
			},
			{
				type: "min",
				params: [3, "First name can't be less than 3 characters"],
			},
			{
				type: "max",
				params: [15, "First name can't be more than 15 characters"],
			},
		],
	},
	{
		id: "fatherName",
		label: "Middle Name *",
		placeholder: "Middle Name",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		elementWidth: 6,
		validations: [
			{
				type: "required",
				params: ["First name is required"],
			},
			{
				type: "min",
				params: [3, "Middle name can't be less than 3 characters"],
			},
			{
				type: "max",
				params: [15, "Middle name can't be more than 15 characters"],
			},
		],
	},
	{
		id: "lastName",
		label: "Last Name *",
		placeholder: "Last Name",
		type: "condSelect",
		helperText: "Last Name as per Commutree",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		elementWidth: 6,
		options: [
			"Bauva",
			"Boricha",
			"Charla",
			"Chhadwa",
			"Chheda",
			"Dagha",
			"Dedhia",
			"Furiya",
			"Gada",
			"Gala",
			"Gindra",
			"Gogri",
			"Karia",
			"Khirani",
			"Khuthia",
			"Mota",
			"Nandu",
			"Nishar",
			"Rambhiya",
			"Rita",
			"Satra",
			"Savla",
			"Shah",
			"Vadhan",
			"Visaria",
			"Vora",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Please specify your last name"],
			},
		],
	},
	{
		id: "gender",
		label: "Gender *",
		placeholder: "",
		type: "radio",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		options: ["Male", "Female"],
		validations: [
			{
				type: "required",
				params: ["gender is required"],
			},
		],
	},
	{
		id: "email",
		label: "Email *",
		placeholder: "email",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		validations: [
			{
				type: "required",
				params: ["email is required"],
			},
			{
				type: "email",
				params: ["please enter a valid email"],
			},
		],
	},
	{
		id: "dob",
		label: "Date of Birth *",
		placeholder: "Date of Birth",
		type: "materialuipicker",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		validations: [
			{
				type: "required",
				params: ["Date of birth can not be empty"],
			},
		],
	},
	{
		id: "phone_number",
		label: "Mobile/WhatsApp Number *",
		placeholder: "phone number",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		validations: [
			{
				type: "required",
				params: ["phone number is required"],
			},
			{
				type: "matches",
				params: [phoneRegExp, "Phone number is not valid"],
			},
			{
				type: "max",
				params: [10, "Phone number can not be more than 10 digits"],
			},
			{
				type: "min",
				params: [10, "Phone number can not be less than 10 digits"],
			},
		],
	},
	{
		id: "aadhar_number",
		label: "Aadhar Number *",
		placeholder: "aadhar number",
		type: "text",
		helperText: "12 digit applicant's aadhar number",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		validations: [
			{
				type: "required",
				params: ["Aadhar number is required"],
			},
			{
				type: "matches",
				params: ["^[0-9]+$", "Aadhar number must only be digits"],
			},
			{
				type: "max",
				params: [12, "Aadhar number can not be more than 12 digits"],
			},
			{
				type: "min",
				params: [12, "Aadhar number can not be less than 12 digits"],
			},
		],
	},
	{
		id: "donationAmt",
		label: "Donation Amount *",
		placeholder: "Enter Amount to Donate",
		type: "text",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Donation amount is required"],
			},
			{
				type: "matches",
				params: ["^[0-9]+$", "School Fee must only be digits"],
			},
		],
	},
];
