export const ScDocumentData = [
	{
		id: "textNote",
		label: "",
		placeholder: "",
		type: "statictext",
		validationType: "string",
		value: "* Please note: 1) All documents are mandatory to submit 2) Maximum 2mb file is allowed 3) only JPG, JPEG, PNG files are allowed",
		validations: [],
		preview: false,
	},
	{
		id: "residenceProof",
		label: "Residence Proof *",
		placeholder:
			"Ration Card, Aadhar Card, Electricity bill ,Telephone bill, Rent aggrement, voter id card etc.(first and last page, where name, address and signature are present",
		type: "upload",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Residence proof is required"],
			},
		],
	},
	{
		id: "incomeProof",
		label: "Income Proof *",
		placeholder:
			"Self decleration, Salary Slip, ITR of parents / self / gaurdian as the case may be",
		type: "upload",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Income proof is required"],
			},
		],
	},
	{
		id: "cancelledCheque",
		label: "Copy of cancelled Cheque or Passbook(where name and account number are printed) *",
		placeholder: "Of bank account which amount to be recieved",
		type: "upload",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Cancelled Cheque is required"],
			},
		],
	},
	{
		id: "feeRecieptCheck",
		label: "Do not have latest fee reciept",
		placeholder: "",
		type: "checkbox",
		helperText: "",
		validationType: "string",
		value: "",
		options: ["Do not have latest fee reciept"],
		validations: [],
	},
	{
		id: "feeRecieptCheckTextNote",
		label: "",
		placeholder: "",
		type: "statictext",
		validationType: "string",
		value: "Increases chance of application rejection, It would be applicant's responsiblilty to provide missing document as soon as document is available to SVGA's office",
		validations: [],
		preview: false,
		rules: {
			and: [
				{
					"==": [
						{ var: "feeRecieptCheck" },
						"Do not have latest fee reciept",
					],
				},
			],
		},
		action: ["required"],
	},
	{
		id: "feeReciept",
		label: "Fee reciept *",
		placeholder: "Latest school fee reciept copy.",
		type: "upload",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Fee reciept is required"],
			},
		],
		rules: {
			and: [
				{
					"!=": [
						{ var: "feeRecieptCheck" },
						"Do not have latest fee reciept",
					],
				},
			],
		},
		action: ["required"],
	},
	{
		id: "markSheetCopyCheck",
		label: "Do not have latest marksheet (ચાલુ વર્ષની માર્કશીટ મળી નથી)",
		placeholder: "",
		type: "checkbox",
		helperText: "",
		validationType: "string",
		value: "",
		options: ["Do not have latest marksheet"],
		validations: [],
	},
	{
		id: "markSheetCopyCheckTextNote",
		label: "",
		placeholder: "",
		type: "statictext",
		validationType: "string",
		value: "Increases chance of application rejection, It would be applicant's responsiblilty to provide missing document as soon as document is available to SVGA's office",
		validations: [],
		preview: false,
		rules: {
			and: [
				{
					"==": [
						{ var: "markSheetCopyCheck" },
						"Do not have latest marksheet",
					],
				},
			],
		},
		action: ["required"],
	},
	{
		id: "markSheetCopy",
		label: "Marksheet Copy *",
		placeholder: "Latest marksheet",
		type: "upload",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Marksheet Copy is required"],
			},
		],
		rules: {
			and: [
				{
					"!=": [
						{ var: "markSheetCopyCheck" },
						"Do not have latest marksheet",
					],
				},
			],
		},
		action: ["required"],
	},
	{
		id: "DiffAbled",
		label: "Differently Abled",
		placeholder: "",
		type: "checkbox",
		helperText: "For physically and mentally challenged (Parent /or Child)",
		validationType: "string",
		value: "",
		options: ["Differently Abled"],
		validations: [],
	},
	{
		id: "DiffAbledDoc",
		label: "Upload disability document proof *",
		placeholder: "",
		type: "upload",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Disability document proof is required"],
			},
		],
		rules: {
			and: [
				{
					"==": [{ var: "DiffAbled" }, "Differently Abled"],
				},
			],
		},
		action: ["required"],
	},
	{
		id: "SingleSupportingParent",
		label: "Single Supporting Parent",
		placeholder: "",
		type: "checkbox",
		helperText: "Widowed / Divorced / Separated",
		validationType: "string",
		value: "",
		options: ["Single Supporting Parent"],
		validations: [],
	},
	{
		id: "SingleSupportingParentDoc",
		label: "Upload document proof *",
		placeholder: "Any related document for single supporting parent",
		type: "upload",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Document proof is required"],
			},
		],
		rules: {
			and: [
				{
					"==": [
						{ var: "SingleSupportingParent" },
						"Single Supporting Parent",
					],
				},
			],
		},
		action: ["required"],
	},
];
