import moment from "moment";
import { ApplicationForm, Template, CLAIMS } from "@anginn/flexi-form";

import { rules } from "@form-data/Laptop/rules";
import { personalData } from "@form-data/Laptop/personalData";
import { educationData } from "@form-data/Laptop/educationData";
import { documentData } from "@form-data/Laptop/documentData";
import familyData from "@form-data/Laptop/familyData";
import { LaptopRuleRegData } from "@form-data/Laptop/LaptopRuleRegData";

const approvals = {
	leaves: [
		{
			leafId: "1",
			name: "Level1",
			allowedClaims: [CLAIMS.APPROVER.value],
			mandatory: true,
			atleast: 1,
			leafStatus: true,
		},
		{
			leafId: "2",
			name: "Stage2 approval",
			allowedClaims: [CLAIMS.APPROVER.value],
			mandatory: true,
			atleast: 1,
			leafStatus: true,
		},
		{
			leafId: "3",
			name: "Stage3 approval",
			allowedClaims: [CLAIMS.APPROVER.value],
			mandatory: true,
			atleast: 2,
			leafStatus: true,
		},
	],
};

const formData = {
	id: "ofgjL",
	name: "Laptop Application Form : 2022 - 2023",
	subtitle: "Laptops Scheme - 6, Phase - 2",
	templates: {
		0: {
			name: "Self Declaration",
			template: new Template("fhfg", rules, "", false),
			preview: false,
		},
		1: {
			name: "Personal Details",
			template: new Template("fgjsrt", personalData),
			preview: true,
		},
		2: {
			name: "Education Details",
			template: new Template("shgh", educationData),
			preview: true,
		},
		3: {
			name: "Family Details",
			template: new Template("sherhdr", familyData),
			preview: true,
		},
		4: {
			name: "Document Upload",
			template: new Template("rtdtr+", documentData),
			preview: true,
		},
	},
	start_time: moment("21/7/2022 00:00", "DD/MM/YYYY HH:mm"),
	end_time: moment("24/8/2023 23:59", "DD/MM/YYYY HH:mm"),
	pretext: LaptopRuleRegData,
	approvals: approvals,
};

export const form = Object.assign(new ApplicationForm(), formData);
