export const rules = [
	{
		id: "selfDec1",
		label: "",
		placeholder: "",
		type: "checkbox",
		validationType: "string",
		value: "",
		options: [
			"I belong to Vagad Visa Oswal Community. Studying in any Professional courses and our total family income is below or equal to Rs.5 lacs per annum. No one from my family has previously availed the laptop from the SVGA LAPTOP SCHEME",
		],
		validations: [
			{
				type: "required",
				params: [
					"Self declaration is required to proceed with next steps",
				],
			},
		],
	},
	{
		id: "selfDec2",
		label: "",
		placeholder: "",
		type: "checkbox",
		validationType: "string",
		value: "",
		options: [
			"The applicant shall not sell this laptop till 5 years and when call upon by SVGA show the proof of ownership. Also if use of laptop is not required for applicant learning purpose he/she will return it unconditionally to SVGA, reasonable wear and tear accepted.",
		],
		validations: [
			{
				type: "required",
				params: [
					"Self declaration is needed to proceed with next steps",
				],
			},
		],
	},
];
