import { SET_USER, UPDATE_TEMPLATE } from "../actions/applicant";
import { LOGOUT } from "../actions/auth";

const initialState = { userId: "", applicant: undefined };

export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_TEMPLATE:
			return {
				...state,
				applicant: action.applicant,
			};
		case SET_USER:
			return {
				...state,
				userid: action.id,
			};
		case LOGOUT:
			console.log("LOGOUT in applicant");
			return initialState;
	}
	return state;
};
