export const LaptopRuleRegData = {
	title: "RULES AND REGULATIONS FOR LAPTOP SCHEME – 8 Phase - 1",
	rules: [
		"If there are changes in Communication Details then beneficiaries have to inform SVGA accordingly.",
		"The decision of SVGA regarding acceptance or rejection of the application shall be final & binding on the applicant. SVGA is not bound to reveal reasons for acceptance / rejection of the application.",
		"SVGA has the right to make changes in rules and regulations. If there is a change or addition in rules and regulation then SVGA will inform applicant via email/phone, etc.",
		"Fields marked with asterisk (*) sign are mandatory.",
		"Applicant shall preserve the Copy of the Application.",
		"I agree to abide by above rules & regulations of Laptop Scheme - 8 Phase - 1",
	],
};
