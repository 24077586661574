var i = 0;
let set;
let familyData = [
	{
		id: "familyMembers",
		label: "Number of family members *",
		placeholder: "",
		type: "select",
		helperText: "Number of members in household excluding Applicant",
		validationType: "string",
		value: 1,
		options: [1, 2, 3, 4, 5, 6],
		validations: [
			{
				type: "required",
				params: ["Number of members is required"],
			},
		],
	},
];

// console.log("Rishabh");
for (i = 1; i <= familyData[0].options.length; i++) {
	const rules = {
		and: [
			{
				">=": [{ var: "familyMembers" }, i],
			},
		],
	};
	set = [
		{
			id: "firstName(Member " + i + ")",
			label: "First Name (Member " + i + ")*",
			placeholder: "",
			type: "text",
			validationType: "string",
			value: "",
			rules: rules,
			action: ["required"],
			validations: [
				{
					type: "required",
					params: ["First Name is required"],
				},
			],
		},
		{
			id: "lastName(Member " + i + ")",
			label: "Last Name (Member " + i + ")*",
			placeholder: "",
			type: "text",
			validationType: "string",
			value: "",
			rules: rules,
			action: ["required"],
			validations: [
				{
					type: "required",
					params: ["Last Name is required"],
				},
			],
		},
		{
			id: "relationship(Member" + i + ")",
			label: "Relationship (Member " + i + ")*",
			placeholder: "",
			type: "text",
			helperText: "Relationship with Applicant",
			validationType: "string",
			value: "",
			rules: rules,
			action: ["required"],
			validations: [
				{
					type: "required",
					params: ["Relationship is required"],
				},
			],
		},
		{
			id: "yearlyIncome(Member " + i + ")",
			label: "Yearly Income (Member " + i + ")*",
			placeholder: "",
			type: "text",
			validationType: "string",
			value: "",
			rules: rules,
			action: ["required"],
			validations: [
				{
					type: "required",
					params: ["Yearly income is required"],
				},
				{
					type: "max",
					params: [7, "Income can not be more than 7 digits"],
				},
			],
		},
		{
			id: "qualification(Member " + i + ")",
			label: "Qualification (Member " + i + ")*",
			placeholder: "",
			type: "text",
			validationType: "string",
			value: "",
			rules: rules,
			action: ["required"],
			validations: [
				{
					type: "required",
					params: ["Qualification is required"],
				},
			],
		},
	];
	familyData = familyData.concat(set);
}

// console.log(familyData);
export default familyData;
