import moment from "moment";

const now = moment().format();
const phoneRegExp =
	"^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$";

export const ScPersonalData = [
	{
		id: "name",
		label: "First Name *",
		placeholder: "First Name",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		validations: [
			{
				type: "required",
				params: ["First name is required"],
			},
			{
				type: "min",
				params: [3, "First name can't be less than 3 characters"],
			},
			{
				type: "max",
				params: [15, "First name can't be more than 15 characters"],
			},
		],
	},
	{
		id: "fatherName",
		label: "Father's Name",
		placeholder: "Father's Name",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		validations: [
			{
				type: "min",
				params: [3, "Father's name can't be less than 3 characters"],
			},
			{
				type: "max",
				params: [15, "Father's name can't be more than 15 characters"],
			},
		],
	},
	{
		id: "lastName",
		label: "Last Name *",
		placeholder: "Last Name",
		type: "condSelect",
		helperText: "Last Name as per Commutree",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		options: [
			"Bauva",
			"Boricha",
			"Charla",
			"Chhadwa",
			"Chheda",
			"Dagha",
			"Dedhia",
			"Furiya",
			"Gada",
			"Gala",
			"Gindra",
			"Gogri",
			"Karia",
			"Khirani",
			"Khuthia",
			"Mota",
			"Nandu",
			"Nishar",
			"Rambhiya",
			"Rita",
			"Satra",
			"Savla",
			"Shah",
			"Vadhan",
			"Visaria",
			"Vora",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Please specify your last name"],
			},
		],
	},
	{
		id: "Village",
		label: "Village Name *",
		placeholder: "",
		type: "condSelect",
		helperText: "Village Name",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: true,
		options: [
			"Adhoi",
			"Bhachau",
			"Bhachau - Navagam",
			"Bharudia",
			"Gagodar",
			"Ghanithar",
			"Halra",
			"Kakrava",
			"Kharoi",
			"Lakadiya",
			"Manafra",
			"Nandasar",
			"Nutan Trambo",
			"Rav",
			"Samkhiyari",
			"Shivlakha",
			"Suvai",
			"Thoriyari",
			"Trambo",
			"Vanoi",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Please specify village name"],
			},
		],
	},
	{
		id: "dob",
		label: "Date of Birth *",
		placeholder: "Date of Birth",
		type: "materialuipicker",
		validationType: "string",
		value: "01/01/2030",
		isProfile: true,
		isEditableProfile: true,
		validations: [
			{
				type: "required",
				params: ["Date of birth can not be empty"],
			},
		],
	},
	{
		id: "gender",
		label: "Gender *",
		placeholder: "",
		type: "radio",
		validationType: "string",
		value: "",
		options: ["Male", "Female"],
		isProfile: true,
		isEditableProfile: false,
		validations: [
			{
				type: "required",
				params: ["gender is required"],
			},
		],
	},
];
