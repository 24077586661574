import React, { useState, useEffect } from "react";
import {
	Avatar,
	Button,
	CssBaseline,
	TextField,
	Box,
	Typography,
	Container,
	FormHelperText,
	Link,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { SERVER_URL } from "../config";

export default function ChangeContact() {
	const [aadharNo, setAadharNo] = useState("");
	const [oldContact, setOldContact] = useState("");
	const [newContact, setNewContact] = useState("");
	const [email, setEmail] = useState("");
	const [otpNewContactSMS, setOtpNewContactSMS] = useState("");
	const [otpNewContactEmail, setOtpNewContactEmail] = useState("");
	const [step, setStep] = useState(0);
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");
	const [otpSent, setOtpSent] = useState(false);
	const [loading, setLoading] = useState(false);
	const [timer, setTimer] = useState(30);

	const validateInput = (input, pattern, errorMessage) => {
		return pattern.test(input) ? "" : errorMessage;
	};

	const validateAadhar = (input) => {
		const pattern = /^\d{12}$/;
		const errorMessage = "Aadhar should be a 12-digit number";
		return validateInput(input, pattern, errorMessage);
	};

	const validateContact = (input) => {
		const pattern = /^\d{10}$/;
		const errorMessage = "Contact should be a 10-digit number";
		return validateInput(input, pattern, errorMessage);
	};

	const validateOTP = (input) => {
		const pattern = /^\d{4}$/;
		const errorMessage = "OTP should be a 4-digit number";
		return validateInput(input, pattern, errorMessage);
	};

	const callSearchOldContactAPI = () => {
		// Call API to search old contact using aadharNo
		// Set oldContact and error accordingly
		let temp = {
			aadharNo,
		};
		fetch(`${SERVER_URL}/api/loadOldContact`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(temp),
		})
			.then((res) => res.json())
			.then((json) => {
				if (!json.error) {
					setOldContact(json.contact); // Example old contact
					setError(""); // Clear error upon success
					setSuccess(""); // Clear success upon success
					setStep(1); // Move to the next step
				} else {
					setError(json.errorMsg); // Clear error upon success
				}
			});
	};

	const callValidateNewContactAPI = () => {
		let temp = {
			aadharNo,
			oldContact,
			contact: newContact,
		};
		setLoading(true); // Start loading
		fetch(`${SERVER_URL}/api/validateNewContact`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(temp),
		})
			.then((res) => res.json())
			.then((json) => {
				if (!json.error) {
					setError(json.errorMsg); // Clear error upon success
					setSuccess(""); // Clear success upon success
					setEmail(json.email);
					setOtpSent(true); // Indicate that OTP has been sent
					setStep(2); // Move to the next step
				} else {
					setError(json.errorMsg); // Clear error upon success
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const callVerifyOtpAPI = () => {
		let temp = {
			aadharNo,
			oldContact,
			contact: newContact,
			smsOTP: otpNewContactSMS,
			emailOTP: otpNewContactEmail,
		};
		fetch(`${SERVER_URL}/api/verifyNewContactOTP`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(temp),
		})
			.then((res) => res.json())
			.then((json) => {
				if (!json.error) {
					setError(""); // Clear error upon success
					// setSuccess("Contact number successfully updated"); // Display success message
					// setError(json.errorMsg); // Clear error upon success
					setSuccess(json.successMsg); // Clear success upon success
					setStep(3); // Move to the next step
				} else {
					setError(json.errorMsg); // Clear error upon success
				}
			});
		// Since this is the final step, we don't need to move to the next step
	};

	const handleSubmit = () => {
		if (step === 0) {
			const validationError = validateAadhar(aadharNo);
			if (validationError) return setError(validationError);
			callSearchOldContactAPI();
		} else if (step === 1) {
			const validationError = validateContact(newContact);
			if (validationError) return setError(validationError);
			callValidateNewContactAPI();
		} else if (step === 2) {
			const smsOtpValidationError = validateOTP(otpNewContactSMS);
			const emailOtpValidationError = validateOTP(otpNewContactEmail);
			if (smsOtpValidationError || emailOtpValidationError) {
				return setError(
					smsOtpValidationError || emailOtpValidationError
				);
			}
			callVerifyOtpAPI();
		}
	};

	useEffect(() => {
		let interval;
		if (loading) {
			interval = setInterval(() => {
				setTimer((prevTimer) => {
					if (prevTimer === 0) {
						setLoading(true);
						clearInterval(interval);
						return 30; // Reset timer to 30 seconds
					} else {
						return prevTimer - 1;
					}
				});
			}, 1000);
		} else {
			clearInterval(interval);
			setTimer(30);
		}
		return () => clearInterval(interval);
	}, [loading]);

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Avatar sx={{ m: 1, backgroundColor: "secondary.main" }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Change Contact Number
				</Typography>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="aadharNo"
					label="Aadhar Card Number"
					name="aadharNo"
					autoFocus
					onChange={(e) => setAadharNo(e.target.value)}
					value={aadharNo}
					disabled={step > 0}
				/>
				{step >= 1 && (
					<Typography variant="body1" gutterBottom>
						Old Contact Number: {oldContact}
					</Typography>
				)}
				{step >= 1 && (
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="newContact"
						label="New Contact Number"
						name="newContact"
						onChange={(e) => setNewContact(e.target.value)}
						value={newContact}
						disabled={step >= 2}
					/>
				)}
				{step >= 2 && (
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="otpNewContactSMS"
						label="SMS OTP for New Contact"
						name="otpNewContactSMS"
						onChange={(e) => setOtpNewContactSMS(e.target.value)}
						value={otpNewContactSMS}
						disabled={step >= 3}
					/>
				)}
				{step >= 2 && (
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="otpNewContactEmail"
						label="Email OTP for New Contact"
						name="otpNewContactEmail"
						onChange={(e) => setOtpNewContactEmail(e.target.value)}
						value={otpNewContactEmail}
						disabled={step >= 3}
					/>
				)}
				<Box sx={{ mt: 2 }}>
					{error && (
						<FormHelperText sx={{ color: "red" }}>
							{error}
						</FormHelperText>
					)}
				</Box>
				{loading ? (
					<Typography variant="body1" gutterBottom>
						Resending OTP in {timer} seconds...
					</Typography>
				) : (
					<Button
						fullWidth
						variant="contained"
						color="primary"
						sx={{ mt: 2 }}
						onClick={handleSubmit}
						disabled={loading}
					>
						{step === 0 && "Search Old Contact"}
						{step === 1 && "Validate New Contact"}
						{step === 2 && "Verify OTP for New Contact"}
					</Button>
				)}
				<Box sx={{ mt: 2 }}>
					{success && (
						<>
							<Link href="/login" variant="body2">
								{"Back to Login"}
							</Link>
							<FormHelperText sx={{ color: "green" }}>
								{success}
							</FormHelperText>
						</>
					)}
					{otpSent && email && (
						<FormHelperText sx={{ color: "blue" }}>
							OTP has been sent to Contact {newContact} and
							emailed to {email}
						</FormHelperText>
					)}
					{otpSent && !email && (
						<FormHelperText sx={{ color: "blue" }}>
							OTP has been sent to Contact {newContact} and email
						</FormHelperText>
					)}
				</Box>
			</Box>
		</Container>
	);
}
