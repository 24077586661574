import moment from "moment";

const now = moment().format();
const phoneRegExp =
	"^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$";

export const ScPersonalData = [
	{
		id: "name",
		label: "First Name *",
		placeholder: "First Name",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		validations: [
			{
				type: "required",
				params: ["First name is required"],
			},
			{
				type: "min",
				params: [3, "First name can't be less than 3 characters"],
			},
			{
				type: "max",
				params: [15, "First name can't be more than 15 characters"],
			},
		],
	},
	{
		id: "fatherName",
		label: "Father's Name",
		placeholder: "Father's Name",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		validations: [
			{
				type: "min",
				params: [3, "Father's name can't be less than 3 characters"],
			},
			{
				type: "max",
				params: [15, "Father's name can't be more than 15 characters"],
			},
		],
	},
	{
		id: "gandfatherName",
		label: "Grand Father's Name *",
		placeholder: "Grand Father's Name",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		validations: [
			{
				type: "required",
				params: ["Grand Father's name is required"],
			},
			{
				type: "min",
				params: [
					3,
					"Grand father's name can't be less than 3 characters",
				],
			},
			{
				type: "max",
				params: [
					15,
					"Grand father's name can't be more than 15 characters",
				],
			},
		],
	},
	{
		id: "greatGrandFatherName",
		label: "Great Grand Father's Name",
		placeholder: "Great Grand Father's Name",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		validations: [],
	},
	{
		id: "lastName",
		label: "Last Name *",
		placeholder: "Last Name",
		type: "condSelect",
		helperText: "Last Name as per Commutree",
		validationType: "string",
		value: "",
		isProfile: true,
		options: [
			"Bauva",
			"Boricha",
			"Charla",
			"Chhadwa",
			"Chheda",
			"Dagha",
			"Dedhia",
			"Furiya",
			"Gada",
			"Gala",
			"Gindra",
			"Gogri",
			"Karia",
			"Khirani",
			"Khuthia",
			"Mota",
			"Nandu",
			"Nishar",
			"Rambhiya",
			"Rita",
			"Satra",
			"Savla",
			"Shah",
			"Vadhan",
			"Visaria",
			"Vora",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Please specify your last name"],
			},
		],
	},

	{
		id: "dob",
		label: "Date of Birth *",
		placeholder: "Date of Birth",
		type: "materialuipicker",
		validationType: "string",
		value: "01/01/2030",
		isProfile: true,
		validations: [
			{
				type: "required",
				params: ["Date of birth can not be empty"],
			},
		],
	},
	{
		id: "gender",
		label: "Gender *",
		placeholder: "",
		type: "radio",
		validationType: "string",
		value: "",
		isProfile: true,
		options: ["Male", "Female"],
		validations: [
			{
				type: "required",
				params: ["gender is required"],
			},
		],
	},
	{
		id: "email",
		label: "Email Id (Parent/Student) *",
		placeholder: "email",
		type: "text",
		validationType: "string",
		helperText:
			"This is confirmation email hence it is mandatory. You Shall recieve your confirmation mail on this email id",
		value: "",
		isProfile: true,
		validations: [
			{
				type: "required",
				params: ["email is required"],
			},
			{
				type: "email",
				params: ["please enter a valid email"],
			},
		],
	},
	{
		id: "aadhar_number",
		label: "Aadhar Number *",
		placeholder: "aadhar number",
		type: "text",
		validationType: "string",
		helperText: "12 digits applicant's aadhar card number",
		value: "",
		isProfile: true,
		validations: [
			{
				type: "required",
				params: ["Aadhar number is required"],
			},
			{
				type: "matches",
				params: ["^[0-9]+$", "Aadhar number must only be digits"],
			},
			{
				type: "max",
				params: [12, "Aadhar number can not be more than 12 digits"],
			},
			{
				type: "min",
				params: [12, "Aadhar number can not be less than 12 digits"],
			},
		],
	},
	{
		id: "phone_number",
		label: "Mobile/WhatsApp Number *",
		placeholder: "phone number",
		type: "text",
		helperText:
			"For Communication Purpose this is a mandatory mobile number (Parent or Student). Ten digits only.",
		validationType: "string",
		value: "",
		isProfile: true,
		validations: [
			{
				type: "required",
				params: ["phone number is required"],
			},
			{
				type: "matches",
				params: [phoneRegExp, "Phone number is not valid"],
			},
			{
				type: "max",
				params: [10, "Phone number can not be more than 10 digits"],
			},
			{
				type: "min",
				params: [10, "Phone number can not be less than 10 digits"],
			},
		],
	},
	{
		id: "FatherVillage",
		label: "Village Name *",
		placeholder: "",
		type: "condSelect",
		helperText: "Father's Village Name",
		validationType: "string",
		value: "",
		isProfile: true,
		options: [
			"Adhoi",
			"Bhachau",
			"Bhachau - Navagam",
			"Bharudia",
			"Gagodar",
			"Ghanithar",
			"Halra",
			"Kakrava",
			"Kharoi",
			"Lakadiya",
			"Manafra",
			"Nandasar",
			"Nutan Trambo",
			"Rav",
			"Samkhiyari",
			"Shivlakha",
			"Suvai",
			"Thoriyari",
			"Trambo",
			"Vanoi",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Please specify village name"],
			},
		],
	},

	{
		id: "MothersMainden",
		label: "Mother's Maiden Name *",
		placeholder: "Please specify",
		type: "text",
		helperText:
			"Write Mother's Pre-Marriage full name along with Great Grandfather's name",
		validationType: "string",
		value: "",
		isProfile: true,
		validations: [
			{
				type: "required",
				params: ["Please provide mother's maiden name"],
			},
		],
	},
	{
		id: "MotherVillege",
		label: "Mother's Village Name *",
		placeholder: "",
		type: "condSelect",
		helperText: "Mother's Village Name",
		validationType: "string",
		value: "",
		isProfile: true,
		options: [
			"Adhoi",
			"Bhachau",
			"Bhachau - Navagam",
			"Bharudia",
			"Gagodar",
			"Ghanithar",
			"Halra",
			"Kakrava",
			"Kharoi",
			"Lakadiya",
			"Manafra",
			"Nandasar",
			"Nutan Trambo",
			"Rav",
			"Samkhiyari",
			"Shivlakha",
			"Suvai",
			"Thoriyari",
			"Trambo",
			"Vanoi",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Please specify village name"],
			},
		],
	},

	{
		id: "parent_phone_number",
		label: "Parent's mobile number *",
		placeholder: "phone number",
		type: "text",
		helperText: "10 digits only",
		validationType: "string",
		value: "",
		isProfile: true,
		validations: [
			{
				type: "required",
				params: ["Parent's mobile number is required"],
			},
			{
				type: "matches",
				params: [phoneRegExp, "Phone number is not valid"],
			},
			{
				type: "max",
				params: [10, "Phone number can not be more than 10 digits"],
			},
			{
				type: "min",
				params: [10, "Phone number can not be less than 10 digits"],
			},
		],
	},
	{
		id: "homeAddress",
		label: "Home Address *",
		placeholder: "home address",
		type: "textarea",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Home address is required"],
			},
			{
				type: "min",
				params: [10, "home address can't be less than 10 characters"],
			},
		],
	},

	{
		id: "residenceType",
		label: "Residence Type",
		placeholder: "",
		type: "radio",
		validationType: "string",
		value: "",
		options: ["Ownership", "Tenancy", "Rented"],
		validations: [
			{
				type: "required",
				params: ["Resdidence type is required"],
			},
		],
	},
	{
		id: "familyMembers",
		label: "Number of family Members",
		placeholder: "Family Members",
		type: "text",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "matches",
				params: ["^[0-9]+$", "Family members must only be digits"],
			},
		],
	},
	{
		id: "scholarshipStandard",
		label: "Scholarship standard *",
		placeholder: "",
		type: "select",
		helperText: "Standard for which Scholarship is Applied in 2024-25",
		validationType: "string",
		value: "",
		options: [
			"Nursery",
			"Junior KG",
			"Senior KG",
			"1st",
			"2nd",
			"3rd",
			"4th",
			"5th",
			"6th",
			"7th",
			"8th",
			"9th",
			"10th",
		],
		validations: [
			{
				type: "required",
				params: ["Scholorship Standard is required"],
			},
		],
	},
	{
		id: "standardAppeared",
		label: "Standard Appeared in 2023-24 *",
		placeholder: "",
		type: "select",
		helperText: "",
		validationType: "string",
		value: "",
		options: [
			"Play Group",
			"Nursery",
			"Junior KG",
			"Senior KG",
			"1st",
			"2nd",
			"3rd",
			"4th",
			"5th",
			"6th",
			"7th",
			"8th",
			"9th",
			"10th",
		],
		validations: [
			{
				type: "required",
				params: ["Standard is required"],
			},
		],
	},
	{
		id: "medium",
		label: "Medium",
		placeholder: "",
		type: "radio",
		validationType: "string",
		value: "",
		options: ["Gujarati", "English"],
		validations: [],
	},
	{
		id: "schoolName",
		label: "School Name and Area *",
		placeholder: "ST MARY SCHOOL",
		type: "textarea",
		hyperText: "Avoid special characters like (') quotation mark",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["School name and address is required"],
			},
			{
				type: "min",
				params: [
					10,
					"School name and address can't be less than 10 characters",
				],
			},
		],
	},
	{
		id: "schoolFee",
		label: "School Fees (INR) *",
		placeholder: "",
		type: "text",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["School fees is required"],
			},
			{
				type: "matches",
				params: ["^[0-9]+$", "School Fee must only be digits"],
			},
		],
	},
	{
		id: "Marks2122",
		label: "Total marks in 2022-23 *",
		placeholder: "",
		type: "text",
		helperText: "Please fill your total marks obtained in 2022-23",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Total marks is required"],
			},
		],
	},
	{
		id: "Perentage2022",
		label: "Percentage in 2022-23 *",
		placeholder: "",
		type: "text",
		helperText: "Percentage in 2022-23 or Grade",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Previous year % is required"],
			},
		],
	},
	{
		id: "Marks2324",
		label: "Total marks in 2023-24 *",
		placeholder: "",
		type: "text",
		helperText: "Please fill your total marks obtained in 2023-24",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Total marks is required"],
			},
		],
	},
	{
		id: "Perentage2324",
		label: "Percentage in 2023-24 *",
		placeholder: "",
		type: "text",
		helperText: "Percentage in 2023-24 or Grade",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Previous year % is required"],
			},
		],
	},
	{
		id: "fathersOccupation",
		label: "Father's Occupation *",
		placeholder: "",
		type: "condSelect",
		validationType: "string",
		value: "",
		options: [
			"Own Shop",
			"Service",
			"Professional",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Father's occupation is required"],
			},
		],
	},
	{
		id: "familyIncome",
		label: "Total Family Income *",
		placeholder: "",
		type: "text",
		helperText:
			"Income of all working members of household (Father, Brother, Mother, Sister) jointly",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Family Income is required"],
			},
			{
				type: "matches",
				params: ["^[0-9]+$", "Family Income must only be digits"],
			},
			{
				type: "max",
				params: [7, "Family Income can not be more than 7 digits"],
			},
		],
	},

	// {
	//   id: "KeyboardDatePicker",
	//   label: "Date of Birth",
	//   placeholder: "DOB",
	//   type: "KeyboardDatePicker",
	//   validationType: "date",
	//   value: "",
	//   validations: [
	//     {
	//       type: "required",
	//       params: ["Date of birth can not be empty"],
	//     },
	//   ],
	// },
];
