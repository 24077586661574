import React from "react";
import { DynamicForm } from "@anginn/flexi-form";
import { useSelector } from "react-redux";

// const MyCustomFinalSubmit = ({ submittedOn, formData }) => {
// 	return (
// 		<div>
// 			<h2>Thank you for submitting the form!</h2>
// 			{submittedOn && (
// 				<p>We received your last response as of {submittedOn}.</p>
// 			)}
// 		</div>
// 	);
// };

export default function FlexiForm(props) {
	const { jsonData, formId } = props;
	const profile = useSelector((state) => state.applicant.applicant.profile);

	return (
		<DynamicForm
			formId={formId}
			application={jsonData}
			cbSubmit={props.handleSubmission}
			// FinalSubmitComponent={MyCustomFinalSubmit}
			profile={profile}
		/>
	);
}
