import moment from "moment";

const now = moment().format();
const phoneRegExp =
	"^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$";

export const personalData = [
	{
		id: "name",
		label: "First Name *",
		placeholder: "First Name",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		elementWidth: 6,
		validations: [
			{
				type: "required",
				params: ["First name is required"],
			},
			{
				type: "min",
				params: [3, "First name can't be less than 3 characters"],
			},
			{
				type: "max",
				params: [15, "First name can't be more than 15 characters"],
			},
		],
	},
	{
		id: "fatherName",
		label: "Father's Name *",
		placeholder: "Father's Name",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		elementWidth: 6,
		validations: [
			{
				type: "required",
				params: ["First name is required"],
			},
			{
				type: "min",
				params: [3, "Father's name can't be less than 3 characters"],
			},
			{
				type: "max",
				params: [15, "Father's name can't be more than 15 characters"],
			},
		],
	},
	{
		id: "gandfatherName",
		label: "Grand Father's Name *",
		placeholder: "Grand Father's Name",
		type: "text",
		isProfile: true,
		isEditableProfile: false,
		validationType: "string",
		value: "",
		elementWidth: 6,
		validations: [
			{
				type: "required",
				params: ["Gand Father's name is required"],
			},
			{
				type: "min",
				params: [
					3,
					"Grand father's name can't be less than 3 characters",
				],
			},
			{
				type: "max",
				params: [
					15,
					"Grand father's name can't be more than 15 characters",
				],
			},
		],
	},
	{
		id: "lastName",
		label: "Last Name *",
		placeholder: "Last Name",
		type: "condSelect",
		helperText: "Last Name as per Commutree",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		elementWidth: 6,
		options: [
			"Bauva",
			"Boricha",
			"Charla",
			"Chhadwa",
			"Chheda",
			"Dagha",
			"Dedhia",
			"Furiya",
			"Gada",
			"Gala",
			"Gindra",
			"Gogri",
			"Karia",
			"Khirani",
			"Khuthia",
			"Mota",
			"Nandu",
			"Nishar",
			"Rambhiya",
			"Rita",
			"Satra",
			"Savla",
			"Shah",
			"Vadhan",
			"Visaria",
			"Vora",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Please specify your last name"],
			},
		],
	},
	// {
	// 	id: "OtherLastName",
	// 	label: "Other",
	// 	placeholder: "Please specify",
	// 	type: "text",
	// 	helperText: "If your last name is not mentioned in above list",
	// 	validationType: "string",
	// 	value: "",
	// 	validations: [],
	// },
	{
		id: "village",
		label: "Village Name *",
		placeholder: "Village",
		type: "condSelect",
		helperText: "",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		options: [
			"Adhoi",
			"Bhachau",
			"Bhachau - Navagam",
			"Bharudia",
			"Gagodar",
			"Ghanithar",
			"Halra",
			"Kakrava",
			"Kharoi",
			"Lakadiya",
			"Manafra",
			"Nandasar",
			"Nutan Trambo",
			"Rav",
			"Samkhiyari",
			"Shivlakha",
			"Suvai",
			"Thoriyari",
			"Trambo",
			"Vanoi",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Please specify village name"],
			},
		],
	},
	// {
	// 	id: "Village",
	// 	label: "Other",
	// 	placeholder: "Please specify",
	// 	type: "text",
	// 	helperText: "If your village is out of Vagad",
	// 	validationType: "string",
	// 	value: "",
	// 	validations: [
	// 		{
	// 			type: "min",
	// 			params: [3, "Village can't be less than 3 characters"],
	// 		},
	// 		{
	// 			type: "max",
	// 			params: [25, "Village can't be more than 25 characters"],
	// 		},
	// 	],
	// },
	{
		id: "gender",
		label: "Gender *",
		placeholder: "",
		type: "radio",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		options: ["Male", "Female"],
		validations: [
			{
				type: "required",
				params: ["gender is required"],
			},
		],
	},
	{
		id: "email",
		label: "Email *",
		placeholder: "email",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		validations: [
			{
				type: "required",
				params: ["email is required"],
			},
			{
				type: "email",
				params: ["please enter a valid email"],
			},
		],
	},
	{
		id: "dob",
		label: "Date of Birth *",
		placeholder: "Date of Birth",
		type: "materialuipicker",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		validations: [
			{
				type: "required",
				params: ["Date of birth can not be empty"],
			},
		],
	},
	{
		id: "maritalStatus",
		label: "Marital Status *",
		placeholder: "",
		type: "radio",
		validationType: "string",
		value: "",
		options: ["Single", "Married"],
		validations: [
			{
				type: "required",
				params: ["Marital Status is required"],
			},
		],
	},
	{
		id: "phone_number",
		label: "Mobile/WhatsApp Number *",
		placeholder: "phone number",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		validations: [
			{
				type: "required",
				params: ["phone number is required"],
			},
			{
				type: "matches",
				params: [phoneRegExp, "Phone number is not valid"],
			},
			{
				type: "max",
				params: [10, "Phone number can not be more than 10 digits"],
			},
			{
				type: "min",
				params: [10, "Phone number can not be less than 10 digits"],
			},
		],
	},
	{
		id: "aadhar_number",
		label: "Aadhar Number *",
		placeholder: "aadhar number",
		type: "text",
		helperText: "12 digit applicant's aadhar number",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		validations: [
			{
				type: "required",
				params: ["Aadhar number is required"],
			},
			{
				type: "matches",
				params: ["^[0-9]+$", "Aadhar number must only be digits"],
			},
			{
				type: "max",
				params: [12, "Aadhar number can not be more than 12 digits"],
			},
			{
				type: "min",
				params: [12, "Aadhar number can not be less than 12 digits"],
			},
		],
	},
	{
		id: "homeAddress",
		label: "Home Address *",
		placeholder: "home address",
		type: "textarea",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: true,
		validations: [
			{
				type: "required",
				params: ["Home address is required"],
			},
			{
				type: "min",
				params: [10, "home address can't be less than 10 characters"],
			},
		],
	},
	{
		id: "familyIncome",
		label: "Total income of family *",
		placeholder: "Family Income",
		type: "text",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Family income is required"],
			},
			{
				type: "matches",
				params: ["^[0-9]+$", "Income must only be in digits"],
			},
			{
				type: "max",
				params: [7, "Family Income can not be more than 7 digits"],
			},
		],
	},
	{
		id: "parentOccupation",
		label: "Parent's occupation*",
		placeholder: "Parent's Occupation",
		type: "text",
		helperText: "Occupation of father / mother *",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Parent's occupation is required"],
			},
		],
	},
	{
		id: "parent_phone_number",
		label: "Parent's mobile number *",
		placeholder: "phone number",
		type: "text",
		validationType: "string",
		value: "",
		validations: [
			{
				type: "required",
				params: ["Parent's mobile number is required"],
			},
			{
				type: "matches",
				params: [phoneRegExp, "Phone number is not valid"],
			},
			{
				type: "max",
				params: [10, "Phone number can not be more than 10 digits"],
			},
			{
				type: "min",
				params: [10, "Phone number can not be less than 10 digits"],
			},
		],
	},

	// {
	//   id: "KeyboardDatePicker",
	//   label: "Date of Birth",
	//   placeholder: "DOB",
	//   type: "KeyboardDatePicker",
	//   validationType: "date",
	//   value: "",
	//   validations: [
	//     {
	//       type: "required",
	//       params: ["Date of birth can not be empty"],
	//     },
	//   ],
	// },
];
