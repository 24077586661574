import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ component: Component, ...rest }) => {
	const token = useSelector((state) => state.auth.token);

    if (token == null) {
        return <Navigate to="/login" state={{ from: rest.path }} />;
    }

    return Component ? <Component {...rest} /> : <Outlet />;
};

export default ProtectedRoute;
