import moment from "moment";

const now = moment().format();
const phoneRegExp =
	"^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$";

export const BusinessData = [
	{
		id: "companyName",
		label: "Name of the business / firm /shop *",
		placeholder: "Apple / Google / Wipro",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		validations: [
			{
				type: "required",
				params: ["Name of the business / firm /shop is required"],
			},
			{
				type: "min",
				params: [
					3,
					"Name of the business / firm /shop can't be less than 3 characters",
				],
			},
			{
				type: "max",
				params: [
					25,
					"Name of the business / firm /shop can't be more than 15 characters",
				],
			},
		],
	},
	{
		id: "name",
		label: "Name of Proprietor/ Partner/Director *",
		placeholder: "First Name",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		validations: [
			{
				type: "required",
				params: ["First name is required"],
			},
			{
				type: "min",
				params: [3, "First name can't be less than 3 characters"],
			},
			{
				type: "max",
				params: [15, "First name can't be more than 15 characters"],
			},
		],
	},
	{
		id: "fatherName",
		label: "Name of Proprietor/ Partner/Director *",
		placeholder: "Father's Name",
		type: "text",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		validations: [
			{
				type: "min",
				params: [3, "Father's name can't be less than 3 characters"],
			},
			{
				type: "max",
				params: [15, "Father's name can't be more than 15 characters"],
			},
		],
	},
	{
		id: "lastName",
		label: "Name of Proprietor/ Partner/Director *",
		placeholder: "Last Name",
		type: "condSelect",
		helperText: "Last Name as per Commutree",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: false,
		options: [
			"Bauva",
			"Boricha",
			"Charla",
			"Chhadwa",
			"Chheda",
			"Dagha",
			"Dedhia",
			"Furiya",
			"Gada",
			"Gala",
			"Gindra",
			"Gogri",
			"Karia",
			"Khirani",
			"Khuthia",
			"Mota",
			"Nandu",
			"Nishar",
			"Rambhiya",
			"Rita",
			"Satra",
			"Savla",
			"Shah",
			"Vadhan",
			"Visaria",
			"Vora",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Please specify your last name"],
			},
		],
	},
	{
		id: "Village",
		label: "Village Name *",
		placeholder: "",
		type: "condSelect",
		helperText: "Village Name",
		validationType: "string",
		value: "",
		isProfile: true,
		isEditableProfile: true,
		options: [
			"Adhoi",
			"Bhachau",
			"Bhachau - Navagam",
			"Bharudia",
			"Gagodar",
			"Ghanithar",
			"Halra",
			"Kakrava",
			"Kharoi",
			"Lakadiya",
			"Manafra",
			"Nandasar",
			"Nutan Trambo",
			"Rav",
			"Samkhiyari",
			"Shivlakha",
			"Suvai",
			"Thoriyari",
			"Trambo",
			"Vanoi",
			"Other (if other please specify)",
		],
		validations: [
			{
				type: "required",
				params: ["Please specify village name"],
			},
		],
	},
];
