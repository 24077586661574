import moment from "moment";
import { ApplicationForm, Template } from "@anginn/flexi-form";

import { rules } from "@form-data/Laptop/rules";
import { personalData } from "@form-data/Laptop/personalData";
import { educationData } from "@form-data/Laptop/educationData";
import { documentData } from "@form-data/Laptop/documentData";
import familyData from "@form-data/Laptop/familyData";
import { LaptopRuleRegData } from "@form-data/Laptop/LaptopRuleRegData";

export const form = new ApplicationForm(
	"laju23",
	"Laptop Application Form : 2023 - 2024",
	"Laptops Scheme - 7, Phase - 1",
	{
		0: {
			name: "Self Declaration",
			template: new Template("cvfd", rules, "", false),
			preview: false,
		},
		1: {
			name: "Personal Details",
			template: new Template("gfrt", personalData),
			preview: true,
		},
		2: {
			name: "Education Details",
			template: new Template("vbgb", educationData),
			preview: true,
		},
		3: {
			name: "Family Details",
			template: new Template("mjnh", familyData),
			preview: true,
		},
		4: {
			name: "Document Upload",
			template: new Template("trvc+", documentData),
			preview: true,
		},
	},
	moment("15/6/2023 00:00", "DD/MM/YYYY HH:mm"),
	moment("27/6/2023 23:59", "DD/MM/YYYY HH:mm"),
	LaptopRuleRegData
);
