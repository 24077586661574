import { FIREBASE_URL } from "../../config";

export const createTemplate = () => {
	console.log("createTemplate");
	return async (dispatch, getState) => {
		const ACCESS_TOKEN = getState().auth.token;
		const application_template = getState().forms.applications;
		if (application_template !== undefined) {
			const response = await fetch(
				`${FIREBASE_URL}forms.json?auth=${ACCESS_TOKEN}`,
				{
					method: "PATCH",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(application_template),
				}
			);

			if (!response.ok) {
				// throw new Error("Something went wrong!!!");
			}
			const resData = await response.json();
		}
	};
};

export const deleteData = () => {
	return async (dispatch, getState) => {
		// console.log(getState);

		const response = await fetch(`${FIREBASE_URL}applicants.json`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			// body: JSON.stringify({ sdf: "sdf" }),
		});

		if (!response.ok) {
			throw new Error("Something went wrong!!!");
		}

		const resData = await response.json();
	};
};
